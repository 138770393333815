import React from 'react';
import styles from './ArticleHighlight.module.css';

const generateTranslatedUrl = (originalUrl) => {
    const baseUrl = 'https://translate.google.com/translate?';
    const params = new URLSearchParams({
      _x_tr_sch: 'http',
      _x_tr_sl: 'zh-CN',   // Set source language 
      _x_tr_tl: 'en-US',   // Set target language
      _x_tr_hl: 'en-US',   // Set interface language
      _x_tr_pto: 'wapp' // Parameter for webpage translation
    });
    return`${baseUrl}u=${encodeURIComponent(originalUrl)}&${params.toString()}`;
  };

const ArticleHighlight = (props) => {
    console.log(generateTranslatedUrl(props.article.link))
    return (
        <div className={styles.container}>
            <a href={generateTranslatedUrl(props.article.link)}>
                <div className={`${styles.section} ${styles.image}`}>
                    {/* <img src={props.article.image} width="100%" height="150px" alt=""/> */}
                </div>
                <div className={`${styles.section} ${styles.title}`}>
                    <h3>{props.article.title}</h3>
                </div>
                <div className={`${styles.section} ${styles.description}`}>
                    {props.article.summary}
                </div>
            </a>
            <div className={`${styles.section} ${styles.details}`}>
                Source: {props.article.website}
            </div>
            
        </div>
    );
}

export default ArticleHighlight;