import React from 'react';
import styles from './CountryIcon.module.css';

// Use this to select the image properties https://flagpedia.net/download/api

const Flag = (props) => {
    const width = 160;
    const height = 120;
    const cca2 = props.country.cca2.toLowerCase();
    return (
        <img
            type="image/webp"
            srcSet={`https://flagcdn.com/${width}x${height}/${cca2}.webp`}
            alt={props.country.name}
            width={width}
            height={height}
        />
    );
};

export default Flag;