import React from "react";
import styles from "./Nav.module.css";

const Nav = () => {
    return (
        <nav className={styles.container}>
            <div className={styles.section}>&nbsp;</div>
            <div className={`${styles.section} ${styles.title}`}>
                <h1>Truly Informed</h1>
            </div>
            <div className={`${styles.section} ${styles.quote}`}>
                Wisdom is best viewed from multiple perspectives
                {/* "You never really understand a person until you consider things from his point of view */}
            </div>
        </nav>
    );
}


export default Nav;