import React, { useState, useEffect } from 'react';
import axios, { all } from 'axios';
import CountryHighlights from './CountryHighlights';
import styles from './WorldHighlights.module.css';

// Resource: https://restcountries.com/#endpoints-all

const WorldHighlights = () => {
    const [headlines, setHeadlines] = useState([]);
    // const [countriesData, setCountryData] = useState([]);
    // const codes = ['IN', 'CN',
    //     //   'US', 
    //     'ID', 'PK', 'NG', 'BR', 'BD', 'RU', 'MX', 'ET', 'JP'];


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/headlines/`)
            .then(response => {
                setHeadlines(response.data.headlines);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);
    // useEffect(() => {
    //     axios.get('https://restcountries.com/v3.1/all')
    //         .then(response => {
    //             setCountryData(response.data);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }, []);


    // const countries = countriesData.length === 0 ? [] : codes.map(country => {
    //     const countryData = countriesData.find(c => c.cca2 === country);
    //     // console.log(countryData)
    //     // console.log(countryData.languages);
    //     const nativeLanguage = Object.keys(countryData.languages).find(lang => lang !== 'eng') || 'eng';
    //     console.log(nativeLanguage, countryData.name.nativeName)
    //     return {
    //         name: countryData.name.common,
    //         cca2: countryData.cca2.toLowerCase(),
    //         nativeName: countryData.name.nativeName[nativeLanguage].common,
    //         population: countryData.population,
    //     }
    // })

    return (
        <div>
            <div className={styles.world}>
                {headlines.map(countryHeadlines => (
                    <div className={styles.country}>
                        <CountryHighlights country={countryHeadlines.country} headlines={countryHeadlines.headlines} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WorldHighlights;