import React from 'react';
import styles from './CountryHighlights.module.css';
import ArticleHighlight from './ArticleHighlight';
import CountryDetails from './CountryDetails';

const CountryHighlights = (props) => {
  return (
    <div className={styles.container}>
      <div className={`${styles.section} ${styles.country}`}>
        <CountryDetails country={props.country} />
      </div>
      {props.headlines.map(headline => (
        <div className={`${styles.section} ${styles.article}`}>
          <ArticleHighlight article={headline} />
        </div>
      ))}
    </div>
  );
}

export default CountryHighlights;