import React from 'react';
import Flag from './Flag';
import styles from './CountryIcon.module.css'

const CountryIcon = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.flag}>
                <Flag country={props.country} />
            </div>
            <div className={styles.names}>
                {/* <h3>Front page of</h3> */}
                <h2 className={styles.nativeName}>{props.country.nativeName}</h2>
                <h2 className={styles.name}>{props.country.name}</h2>
            </div>
        </div>
    );
    }

export default CountryIcon;