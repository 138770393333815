import React from 'react';
import styles from './CountryDetails.module.css';
import CountryIcon from './CountryIcon';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const globalPop = 8105468872;

const CountryDetails = (props) => {
    return (
        <div className={styles.container}> 
            <div className={styles.icon}>
                <CountryIcon country={props.country} />
            </div>
            <div className={styles.details}>
                {/* <li>{numberWithCommas(props.country.population)} people live here.</li> */}
                {/* {((props.country.population / globalPop) * 100).toFixed(0)}% of all humans live here. */}
            </div>
        </div>
    );
}

export default CountryDetails;